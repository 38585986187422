
import {createRouter,createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


const routerHistory=createWebHistory()


//这是vue2中使用的，应使用vue3来配置
// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })


// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   }
// ]

const router=createRouter({
    history:routerHistory,
    routes:[
      {
        path: '/',
        name: 'Login',
        component: Login,
      }, 
      { 
        title: '首页', 
        name: 'Home', 
        path: '/Home', 
        component: Home,
      },       
        { 
          title: '支付结果', 
          name: 'Result', 
          path: '/Result', 
          component: () => import('@/views/Result.vue') 
        },
        { 
          title: '临时测试页面', 
          name: 'Test', 
          path: '/Test', 
          component: () => import('@/views/Test.vue') 
        },
        { 
          title: '缴费', 
          name: 'Payment', 
          path: '/Payment', 
          component: () => import('@/views/Payment.vue') 
        },
         { 
          title: '缴费信息', 
          name: 'PaymentInfo', 
          path: '/PaymentInfo', 
          component: () => import('@/views/PaymentInfo.vue') 
        }


    ]

})




export default router


