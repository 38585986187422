<template>
  <div id="container">
    <div class="header">
      <van-nav-bar title="首页" fixed />
    </div>

    <div class="content">
      <!-- <div class="Info">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="Name"
              label="姓名"
              size="large"
              placeholder="姓名"
              required
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="IDcard"
              size="large"
              label="身份证号码"
              required
              placeholder="身份证号码"
              :rules="[{ required: true, message: '请填写身份证号' },{validator, message: '身份证号码格式错误！'}]"
            />
            <van-field
              v-model="Mobile"
              size="large"
              label="联系电话"
              
              :rules="[
              { required: true, message: '请填写您的联系电话！' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
            ]"
            type="tel"
              placeholder="联系电话"              
            />

             <van-field name="finishimgstr" label="现场头像" required>
            <template #input>
              <van-uploader
                v-model="fileList"
                :before-delete="deletefn"
                :after-read="afterReadIdCard"
                multiple
                :max-count="1"
                accept="image/*"
              />
          </template>
          </van-field>

          </van-cell-group>

    
        </van-form>
              
      </div>

      <div style="margin-left: 4vw;margin-right: 4vw;margin-top: 4vw;"  v-show="IsOk">
          <van-button 
            block
            plain
            type="success"
            style="backgroud: #07c160"
            @click="goVerification()"
          >
          
            <h3>人证核验 <span v-show="IsOk"><van-icon style="color:green;" name="success" /></span> </h3>
          </van-button>
        </div>

      <div style="margin-left: 4vw;margin-right: 4vw;margin-top: 4vw;"  v-show="IsOk==false">
          <van-button 
            block
            plain
            type="primary"
            style="backgroud: #07c160"
            @click="goVerification()"
          >
          
            <h3>人证核验  <span v-show="IsOk==false"><van-icon style="color:red;" name="fail" /></span>   </h3>
          </van-button>
        </div> -->

      <div class="wrapper">
        <div class="item" @click="Payment()">
          <div class="itemCenter"><h1>缴费</h1></div>
        </div>
        <div class="item" @click="GetResult()">
          <div class="itemCenter"><h1>查询</h1></div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      ApiUrl: localStorage.getItem("ApiUrl"),
      Name: "",
      IDcard: "",
      Mobile: "",
      fileList: [], //默认是一个空数组
      IsOk: false,
    };
  },
  created() {
    document.title = "智民防疫预约";
    this.Name = sessionStorage.getItem("Name");
    this.IDcard = sessionStorage.getItem("IDcard");
    this.Mobile = sessionStorage.getItem("Mobile");
    if (sessionStorage.getItem("IsOk") == "true") {
      this.IsOk = true;
    }
  },
  methods: {
    More() {
      Toast("开发中");
    },
    ContactUs() {
      this.$router.push("/ContactUs");
    },
    /**
     * 身份证验证
     */
    validator(val) {
      const card15 =
        /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
      const card18 =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return card15.test(val) || card18.test(val);
    },

    // goCheckIn() {
    //   console.log("CheckIn");
    //   if(this.IsOk!=true){
    //     Toast.fail("请进行人证核验！")
    //     return;
    //   }
    //   this.$router.push({
    //       path: "/CheckIn",
    //       query: {
    //         //这里面是写需要传送的值
    //         Name: this.Name,
    //         IDcard: this.IDcard,
    //         Mobile: this.Mobile,
    //       },
    //     });
    // },
    Payment() {
      console.log("PaymentInfo");
      this.$router.push({
        path: "/PaymentInfo",
        // query: {
        //   //这里面是写需要传送的值
        //   Name: this.Name,
        //   IDcard: this.IDcard,
        //   Mobile: this.Mobile,
        // },
      });
    },
    GetResult() {
      console.log("GetResult");
      if (this.Name && this.IDcard && this.Mobile) {
        sessionStorage.setItem("Name", this.Name);
        sessionStorage.setItem("IDcard", this.IDcard);
        sessionStorage.setItem("Mobile", this.Mobile);
        this.$router.push({
          path: "/GetResult",
          query: {
            //这里面是写需要传送的值
            Name: this.Name,
            IDcard: this.IDcard,
            Mobile: this.Mobile,
          },
        });
      } else {
        Toast("请正确填写上方申请人姓名，身份证号码，联系电话！");
      }
    },
    onClickLeft() {
      console.log("返回");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  /* height: 6vh; */
  /* color: #946afe; */
  font-size: 2vh;
  margin-left: 3vw;
  color: black;
}

.content {
  margin-top: 6vh;
  padding-top: 4vh;
  min-height: 94vh;
  height: 100%;
  background-color: #f1f1f3;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}
.wrapper {
  margin: 10px;
  /* 声明一个容器 */
  display: grid;
  /*  声明列的宽度  */
  grid-template-columns: repeat(1, 98%);
  /*  声明行间距和列间距  */
  grid-gap: 10px;
  /*  声明行的高度  */
  grid-template-rows: 150px 150px 150px;
  margin-top: 20px;
}
.van-divider {
  color: blue;
}

.header /deep/ .van-nav-bar__title {
  color: rgb(8, 8, 8) !important;
  font-size: 1.2em;
  font-weight: bold;
}
.header /deep/ .van-nav-bar .van-icon {
  color: white !important;
}
.item {
  text-align: center;
  font-size: 100%;
  color: rgb(2, 2, 2);
  border-radius: 10px;
  background-color: #946afe;
}
.itemCenter {
  margin-top: 40px;
}
</style>