<template>
  <div id="container">
    <div class="header">
      <van-nav-bar
        title="登录"
        style="background: #01978a"
        fixed
      />
    </div>

    <div class="content">
      <div class="logo">
        <!-- ../../src/assets/logo.jpg -->
        <img
          src=""
          class="ImgColor"
          style="margin: 20px auto; width: 200px"
          alt=""
        />
      </div>

      <van-form>
        <van-cell-group inset>
          <van-field
            v-model="name"
            label="姓名"
            size="large"
            placeholder="姓名"
            required
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model="Mobile"
            size="large"
            label="手机号"
            required
            placeholder="手机号"
          />
        </van-cell-group>
      </van-form>
      <div style="margin-left: 4vw; margin-right: 4vw; margin-top: 6vw">
        <van-button
          block
          plain
          type="primary"
          style="backgroud: #07c160"
          @click="GoHome()"
        >
          <h3>登录</h3>
        </van-button>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      ApiUrl: localStorage.getItem("ApiUrl"),
      Code: "",
      name: "",
      Mobile: "",
      Tempvalue: ">>>注册<<<",
    };
  },
  created() {
    document.title = "学生管理平台缴费";
  },
  methods: {
    /**
     * 身份证验证
     */
    validator(val) {
      const card15 =
        /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
      const card18 =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return card15.test(val) || card18.test(val);
    },
    GoHome() {
      if (this.name == "") {
        Toast.fail("请填写姓名！");
      }
      if (this.Mobile == "") {
        Toast.fail("请填写手机号！");
      }

      const forms = new FormData();
      forms.append("Mobile", this.Mobile);
      forms.append("name", this.name);
      this.$axios
        .post(
          "http://schoolmanager.zhimin.group/" + "Admin/Student/API_LoginA",
          forms,
          {
            headers: {
              "Content-type": "multipart/form-data", // 修改内容类型
            },
          }
        )
        .then((res) => {
          console.log(res);
          //如果传入的响应状态码为0，则成功将文件发送给后台
          if (res.data.code === 0) {
            console.log(res);
            console.log(res.data.data.code);
            this.IsOk = true;
            sessionStorage.setItem("Name", res.data.data.name);
            sessionStorage.setItem("IDcard", res.data.data.iDcard); 
            sessionStorage.setItem("Mobile", res.data.data.mobile);
            sessionStorage.setItem("Code", res.data.data.code);
            //localStorage.setItem("ApiUrl","http://zmschool.zhimin.group/" );
            localStorage.setItem("ApiUrl","http://schoolmanager.zhimin.group/" );
            //localStorage.setItem('ApiUrl',"https://localhost:44330/")

            this.$router.push("/PaymentInfo");
          } else {
            Toast.fail(res.data.msg);
            console.log(res.data.msg); //这块是请求失败后台给返回的相应的数据
          }
        })
        .catch((err) => {
          console.log(err);
          Toast(err);
        });
    },

    onClickLeft() {
      console.log("返回");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.header {
  /* height: 6vh; */
  color: cornsilk;
  font-size: 2vh;

  margin-left: 3vw;
}

.content {
  margin-top: 6vh;
  padding-top: 8vh;
  min-height: 94vh;
  height: 100%;
  background-color: #f1f1f3;
}
.logo {
  text-align: center;
}
/*焦点时内容为空*/
.van-ellipsis:focus:before {
  content: none;
}
.memberGrade .van-ellipsis:empty:before {
  content: "请选择（必填）"; /* element attribute*/
  color: #c8c9cc;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}
.reg {
  margin-top: 10vh;
  text-align: center;
  color: cornflowerblue;
}

.header /deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 16px;
}
.header /deep/ .van-nav-bar .van-icon {
  color: white !important;
}
</style>