 import { createApp } from 'vue'
 import router from './router'
 import App from './App.vue'
 import axios from 'axios';
 import 'vant/lib/index.css'

 import Vant from 'vant'


//  import VideoPlayer from 'vue-video-player'
// require('vue-video-player/node_modules/video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')


 const app=createApp(App)
 app.config.globalProperties.$axios = axios;
 axios.defaults.baseURL = '/API'
 axios.defaults.headers.post['Content-Type'] = 'application/json';
 app.config.productionTip = false

 app.use(router)
 app.use(Vant)

 app.mount('#app')

// createApp(App).mount('#app')


// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// step1：引入 axios
// import Axios from 'axios'
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
// Vue.prototype.$axios = Axios
// step3：使每次请求都会带一个 /api 前缀 
//  Axios.defaults.baseURL = '/api'



// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//     ak: '7As7TQGuTBQBMkGkkve5ptd0IphnUfUa'
// })

// // 注册到全局
// Vue.component(VTable.name, VTable)
// Vue.component(VPagination.name, VPagination)


//  Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title
//   }
// })
